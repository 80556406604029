<template>
  <div v-if="isPermission">
    <CCol md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>
          <CRow>
            <CCol md="10" sm="9" col="9">
              <div class="row">
                <img src="/img/icon/store.png" style="width: 32px; height:32px; margin-right: 8px;"/>
                <select
                  class="custom-select col-11"
                  v-model="shopObjectId"
                  disabled
                >
                  <option selected>{{ $t('selectshop') }}</option>
                  <option
                    v-for="shop in shops"
                    :key="shop.objectId"
                    :value="shop.objectId"
                  >
                    {{ shop.shopName }} - {{ shop.branchName }}
                  </option>
                </select>
              </div>
            </CCol>
            <CCol sm="3" md="2" col="3">
              <router-link :to="`/report/voidBillOrder`">
                <CButton id="voidBillOrderDeatilBackButton" block color="light" class="align-items-center" >
                  <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
                </CButton>
              </router-link>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>
          <CRow class="form-group">
            <h2 class="col-md-10 col-sm-9 col-9 font-weight-bold">
              {{ $t('report.txtViodOrder') }}
            </h2>
            <div class="col-md-2 col-sm-3 col-3 text-right text-success">
              <CButton
                block
                color="info"
                v-if="isExport"
                id="voidBillOrderDetailExportButton"
                v-on:click="exportInvalidByDate()"
              >
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
            </div>
          </CRow>
          <div class="row form-group">
            <h4 class="col-md-10 font-weight-bold">
              {{ $t('date') }} {{ date }}
            </h4>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <!-- <CRow>
                  <CCol md="10" sm="10">
                    <select class="custom-select"  v-model="mailshopObjectId">
                      <option selected value="" >เลือก Email</option>
                      <option
                        v-for="mail in accessEmail"
                        :key="mail._id"
                        :value="mail._id" 
                      >
                        {{ mail.email }}
                      </option>
                    </select>
                  </CCol>
                  <CCol md="2" sm="2">
                    <CButton color="success" block>ค้นหา</CButton>
                  </CCol>
                </CRow> -->
              <hr />
              <div style="overflow-x: auto">
                <DataTable
                  id="voidBillOrderDeatilDatatable"
                  :items="items"
                  :fields="fields"
                  :loading="loading"
                  hover
                  border
                  style="min-width: max-content;"
                  
                >
                  <template #show_details="{index}">
                    <td class="py-2 text-center">
                      <CButton
                        :id="'voidBillOrderDeatilDatatableItem'+index"
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        @click="toggleDetails(index)"
                      >
                        {{ $t('detail') }}
                      </CButton>
                    </td>
                  </template>
                </DataTable>
              </div>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getInvalidByDate">
          </pagination>
        </CCardBody>
      </CCard>
    </CCol>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'

export default {
  components: {
    Pagination,
    DataTable,
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      accessEmail: [],
      mailshopObjectId: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users', 'permissions']),
    isPermission() {
      return permis.findPermissionRead('orderDevice', '/report/voidBillOrder')
    },
    isExport() {
      return permis.findPermissionExport('orderDevice', '/report/voidBillOrder')
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    date() {
      return moment(String(this.$route.params.date)).format('DD MMMM YYYY')
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'date',
          label: this.$i18n.t('date'),
          _classes: 'text-left text-dark',
        },
        {
          key: 'orderNumber',
          label: this.$i18n.t('orderNumber'),
          _classes: 'text-left text-dark',
        },
        {
          key: 'orderBy',
          label: 'Email',
          _classes: 'text-left text-dark',
        },
        {
          key: 'status',
          label: this.$i18n.t('status'),
          _classes: 'text-left text-dark',
        },
        {
          key: 'netAmount',
          label: this.$i18n.t('netAmount'),
          _classes: 'text-right text-dark',
        },
        {
          key: 'discountAmount',
          label: this.$i18n.t('discount'),
          _classes: 'text-right text-dark',
        },
        {
          key: 'vatAmount',
          label: this.$i18n.t('vat'),
          _classes: 'text-right text-dark',
        },
        {
          key: 'grandTotal',
          label: this.$i18n.t('netTotal'),
          _classes: 'text-right text-dark',
        },
        {
          key: 'rejectBy',
          label: this.$i18n.t('cancelBy'),
          _classes: 'text-right text-dark',
        },
        {
          key: 'reject_data',
          label: this.$i18n.t('cancelDate'),
          _classes: 'text-right text-dark',
        },
        { key: 'reason', label: this.$i18n.t('reason'), _classes: 'text-left text-dark' },
      ]
    },
    items() {
      let data = this.data
      let detail = []
      let rejectBy = ''
      let reject_data = ''
      let reason = ''
      let receiptNumber = ''

      for (let i = 0; i < data.length; i++) {
        if (data[i].order.rejected_by == undefined) {
          rejectBy = '-'
        } else {
          rejectBy = data[i].order.rejected_by.username
        }

        if (data[i].order.rejected_at == undefined) {
          reject_data = '-'
        } else {
          reject_data = moment(String(data[i].order.rejected_at)).format(
            'DD MMMM YYYY HH:mm:ss'
          )
        }

        if (data[i].order.reason == undefined) {
          reason = '-'
        } else {
          reason = data[i].order.reason
        }

        if (data[i].receiptNumber == undefined) {
          receiptNumber = '-'
        } else {
          receiptNumber = data[i].receiptNumber
        }

        detail.push({
          date: moment(String(data[i].created_at)).format(
            'DD/MM/YYYY HH:mm:ss'
          ),
          orderNumber: receiptNumber,
          netAmount: util.convertCurrency(data[i].netAmount),
          discountAmount: util.convertCurrency(data[i].discountAmount),
          vatAmount: util.convertCurrency(data[i].vatAmount),
          grandTotal: util.convertCurrency(data[i].grandTotal),
          status: data[i].order.status,
          orderBy: data[i].User.email,
          rejectBy: rejectBy,
          reject_data: reject_data,
          reason: reason,
        })
      }
      return detail
    },
  },
  created() {
    this.getInvalidByDate()
    this.getAccessByshop()
  },
  methods: {
    ...util,
    getAccessByshop() {
      const uid = `${localStorage.getItem('shopsUid')}`
      axios
        .get(
          '/shop/v1.0/' +
            uid +
            '/accessgranted/' +
            this.$route.params.shopObjectId
        )
        .then((res) => {
          this.accessEmail = res.data.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getInvalidByDate(page = 1) {
      this.loadingButton = false
      this.loading = true
      const uid = `${localStorage.getItem('shopsUid')}`

      const params = {
        shopObjectId: this.$route.params.shopObjectId,
        startAt: this.$route.params.date,
        endAt: this.$route.params.date,
        page: page,
        limit: 50,
      }
      const headers = {
        shopObjectId: this.$route.params.shopObjectId,
      }
      axios({
        url: '/api/v1.0/' + uid + '/order/getinvalidbydate/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          //this.meta_data.prev_page_url = res.data.paginate.currentPage - 1
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportInvalidByDate(page = 1) {
      const uid = `${localStorage.getItem('shopsUid')}`
      const params = {
        shopObjectId: this.$route.params.shopObjectId,
        startAt: this.$route.params.date,
        endAt: this.$route.params.date,
        page: page,
        limit: 50,
      }
      const headers = {
        shopObjectId: this.$route.params.shopObjectId,
      }

      report({
        url: '/api/v1.0/' + uid + '/order/getinvalidbydate/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'รายงานยกเลิกการขายสินค้าตามเครื่องOrder ' +
              this.$route.params.date +
              '.xlsx'
          ) //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
